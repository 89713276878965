<template>
  <v-skeleton-loader
    height="165px"
    v-if="needLoadingIndicator"
    type="image"
    v-bind="$attrs"
  ></v-skeleton-loader>
  <simple-card v-else>
    <template v-slot:title-left>
      <span class="text-sm-center">Баланс счета {{ balanceLabel }}</span>
    </template>
    <template v-slot:content>
      <div v-if="isMobile" class="mobile-balance">
        <v-sheet
          v-for="item in items"
          @click="itemActionHandler(item)"
          :key="item.title"
        >
          <div>{{ item.title }}</div>
          <div class="font-weight-bold">{{ item.value }} ₽</div>
        </v-sheet>
      </div>
      <div v-else class="d-flex flex-wrap flex-column">
        <div class="d-flex align-stretch">
          <v-sheet
            outlined
            class="px-1 px-sm-3 py-1"
            v-for="item in items"
            :style="item.style || null"
            @click="itemActionHandler(item)"
            :key="item.title"
          >
            <div class="text-caption">{{ item.title }}</div>
            <div class="font-weight-bold text-body-2 d-flex align-center">
              {{ item.value }} ₽
            </div>
          </v-sheet>
        </div>
      </div>
      <div class="d-flex mt-2 flex-wrap-reverse buttons-container">
        <v-btn
          :block="isMobile"
          :small="isMobile"
          class="mr-2 full-button"
          color="primary"
          @click="goToPaymentPage('withoutInsurance')"
        >
          Оплатить {{ withoutInsurance }}
        </v-btn>
        <v-btn
          v-if="!activeBinding.outer"
          :block="isMobile"
          :small="isMobile"
          class="full-button"
          color="primary"
          @click="goToPaymentPage('withInsurance')"
        >
          Оплатить со страховкой {{ withInsurance }}
        </v-btn>
      </div>
    </template>
  </simple-card>
</template>

<script>
import SimpleCard from "./SimpleCard";
import { mapState } from "vuex";
import AccrualsService from "../../services/AccrualsService";
import { getAccrualDate } from "@/shared/helpers/date";
import loadingIndicator from "../../shared/mixins/loadingIndicator";

export default {
  name: "BalanceCard",
  mixins: [loadingIndicator],
  components: {
    SimpleCard,
  },
  data() {
    return {
      loadingFlag: "get_balance",
    };
  },
  computed: {
    ...mapState("account", ["balance"]),
    ...mapState("user", ["activeBinding"]),
    balanceDate() {
      if (this.balance.period) {
        return getAccrualDate(this.balance.period);
      } else {
        return null;
      }
    },
    items() {
      return AccrualsService.getFormattedBalance(this.balance);
    },
    withoutInsurance() {
      const sum = this.balance.balance - this.balance.insurance;

      return sum > 0 ? sum.toFixed(2) + " ₽" : "";
    },
    withInsurance() {
      return this.balance.balance > 0
        ? this.balance.balance.toFixed(2) + " ₽"
        : "";
    },
    btnClass() {
      return this.$vuetify.breakpoint.lgAndDown ? "mt-2" : "";
    },
    isSmallBtn() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    ...mapState("app", {
      isMobile: (state) => state.isMobile,
    }),
    balanceLabel() {
      return this.balanceDate || "" !== ""
        ? "за " + this.balanceDate
        : "недоступен";
    },
  },
  methods: {
    goToPaymentPage(type) {
      const query = {};

      switch (type) {
        case "withInsurance":
          query.withInsurance = 1;
          query.amount = Math.max(this.balance.balance, 0);
          break;
        case "withoutInsurance":
          query.amount = Math.max(this.balance.balance - this.balance.insurance, 0);
          break;
        case "debt":
          query.amount = Math.max(this.activeBinding.debt, 0);
          break;
        case "gp":
          query.amount = Math.max(this.activeBinding.gp, 0);
          break;
      }

      this.$router.push({ name: "payment", query });
    },
    itemActionHandler(item) {
      if (!item.action) return false;

      item.action();
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-container {
  gap: 0.5em;

  .btn {
    height: auto !important;
  }
}

.mobile-balance .v-sheet {
  text-align: left;
  padding: 3px 10px;
}
.mobile-balance .v-sheet > div {
  display: inline-block;
}
.mobile-balance .v-sheet div:nth-child(2) {
  float: right;
}

@media only screen and (max-width: 599.98px) {
  .buttons-container {
    padding: 0 10px;
  }
}
</style>
