<template>
  <router-link
    :to="{ name: item.routeName }"
    class="text-decoration-none main-menu-item-card"
  >
    <simple-card>
      <template v-slot:title-left>
        <!--        <v-icon x-large>{{item.icon || 'mdi-application'}}</v-icon>-->
        <span class="icon-container" v-html="item.icon"></span>
      </template>
      <template v-slot:content>
        <div class="text-h6 text-left">{{ item.title }}</div>
      </template>
    </simple-card>
  </router-link>
</template>

<script>
import SimpleCard from "./SimpleCard";

export default {
  name: "MainMenuItemCard",
  components: {
    SimpleCard,
  },
  props: {
    item: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.main-menu-item-card {
  .v-card {
    background: linear-gradient(#fff, #fff, #fff, #dddcdd);

    .icon-container {
      width: 33px;
    }

    .v-card__text > div.text-h6 {
      font-size: 18px !important;
      font-weight: 500 !important;
    }

    &:hover {
      background: #fff;
      box-shadow: 0 0 10px #4e7ec9 !important;

      .v-card__title > i {
        color: #4e7ec9;
      }

      .v-card__text {
        color: #000;
      }
    }

    &:active {
      background-color: #4e7ec9;

      .v-card__text,
      .v-card__title > i {
        color: #fff;
      }
    }
  }
}
</style>
